import { useAuthenticator } from "@aws-amplify/ui-react";
import { setUpTOTP, updateMFAPreference, verifyTOTPSetup } from "aws-amplify/auth";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { QRCodeSVG } from "qrcode.react";
import React, { FormEvent, MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CYTED_HEALTH } from "../../helpers/strings";
import { AppDispatch } from "../../store";
import { Environment, fetchUserData, selectEnvironment } from "../../store/authSlice";

export const TEST_ID_MFA_SETUP_QR_CODE = "MfaSetupQrCode";
export const TEST_ID_MFA_SETUP_TEXT_CODE = "MfaSetupTextCode";
export const TEST_ID_MFA_SETUP_CONFIRM_BUTTON = "MfaSetupConfirmButton";
export const TEST_ID_MFA_SETUP_SHOW_CODE_BUTTON = "MfaSetupShowCodeButton";
export const TEST_ID_MFA_SETUP_VALIDATION_ERROR = "MfaSetupValidationError";

interface MfaSetupForm {
  busy: boolean;
  error: string | null;
  challengeAnswer: string;
}

// Friendly name displayed in authenticator app
const authenticatorAppName: Record<Environment, string> = {
  "local-development": "Portal (staging)",
  staging: "Portal (staging)",
  "pre-production": "Portal (pre-production)",
  production: CYTED_HEALTH,
};

export const ENABLE_MFA_SUCCESS_MESSAGE = "Enabled multi-factor authentication";

const MFASetup = (): React.JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthenticator((context) => [context.user]);

  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const environment = useSelector(selectEnvironment);

  // Local state
  const [totpSecret, setTotpSecret] = useState<string>("");
  const [totpSecretError, setTotpSecretError] = useState<boolean>(false);
  const [showTotpSecret, setShowTotpSecret] = useState<boolean>(false);
  const [form, setForm] = useState<MfaSetupForm>({
    busy: false,
    error: null,
    challengeAnswer: "",
  });

  useEffect(() => {
    fetchTotpSecret();
  }, []);

  const fetchTotpSecret = async (e?: MouseEvent): Promise<void> => {
    e?.preventDefault();
    try {
      const { sharedSecret } = await setUpTOTP();
      setTotpSecretError(false);
      setTotpSecret(sharedSecret);
    } catch (error) {
      console.error(error);
      setTotpSecretError(true);
    }
  };

  const revealTotpSecret = (e: MouseEvent): void => {
    e.preventDefault();
    setShowTotpSecret(true);
  };

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    setForm((form) => ({ ...form, busy: true, error: null }));
    try {
      await verifyTOTPSetup({ code: form.challengeAnswer });
      await updateMFAPreference({ totp: "ENABLED" });
      await dispatch(fetchUserData());
      enqueueSnackbar(ENABLE_MFA_SUCCESS_MESSAGE, { variant: "success" });
    } catch (e) {
      const error = e instanceof Error ? e.message : "Failed to verify code";
      setForm((form) => ({ ...form, busy: false, error }));
    }
  };

  const appName = authenticatorAppName[environment] ?? CYTED_HEALTH;
  const setupUri = encodeURI(
    `otpauth://totp/${user.username}?secret=${totpSecret}&issuer=${appName}`
  );

  const RetryTotpSecret = (): React.JSX.Element => {
    return (
      <p>
        Failed to retrieve QR code.{" "}
        <a href="" onClick={fetchTotpSecret}>
          Try again.
        </a>
      </p>
    );
  };

  const ManualTotpSecret = (): React.JSX.Element => {
    if (!showTotpSecret) {
      return (
        <a
          href=""
          onClick={revealTotpSecret}
          data-testid={TEST_ID_MFA_SETUP_SHOW_CODE_BUTTON}
        >
          Can’t scan image?
        </a>
      );
    }
    return (
      <span>
        Paste this code into your authenticator app:
        <br />
        <b style={{ wordBreak: "break-all" }} data-testid={TEST_ID_MFA_SETUP_TEXT_CODE}>
          {totpSecret}
        </b>
      </span>
    );
  };

  return (
    <div className="content">
      <p>
        Enable multi-factor authentication (MFA) to add an extra layer of security to your
        account.
      </p>
      <p>
        If you don’t already have one, install a one-time password authenticator app on
        your device.
      </p>
      <div className="columns mt-2">
        <div className="column">
          <h4>1. Scan the QR code</h4>
          {totpSecretError ? (
            <RetryTotpSecret />
          ) : (
            <>
              <p>Use your one-time password authenticator app to scan the QR code.</p>
              <p className="image is-128x128 has-background-white-ter">
                {!!totpSecret && (
                  <QRCodeSVG value={setupUri} data-testid={TEST_ID_MFA_SETUP_QR_CODE} />
                )}
              </p>
              <p className="is-size-7">
                <ManualTotpSecret />
              </p>
            </>
          )}
        </div>
        <div className="column">
          <h4>2. Complete MFA setup</h4>
          <p>
            Enter the six-digit numeric code from your authenticator app to complete MFA
            setup.
          </p>
          <form className="field has-addons mb-0" onSubmit={handleSubmit}>
            <p className="control">
              <input
                required
                type="text"
                maxLength={6}
                pattern="\d{6}"
                id="challengeAnswer"
                value={form.challengeAnswer}
                className="input is-rounded"
                style={{ width: "6rem" }}
                placeholder="123456"
                onChange={(e) =>
                  setForm((form) => ({ ...form, challengeAnswer: e.target.value }))
                }
              />
            </p>
            <p className="control">
              <button
                type="submit"
                className={classNames("button is-primary is-rounded pl-4", {
                  "is-loading": form.busy,
                })}
                data-testid={TEST_ID_MFA_SETUP_CONFIRM_BUTTON}
              >
                Confirm
              </button>
            </p>
          </form>
          {!!form.error && (
            <p
              className="has-text-danger"
              data-testid={TEST_ID_MFA_SETUP_VALIDATION_ERROR}
            >
              {form.error}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MFASetup;
