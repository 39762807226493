import { faLock } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useSelector } from "react-redux";

import { selectForceMfaEnrolment } from "../../store/authSlice";
import NotificationBanner from "../common/NotificationBanner";

export const TEST_ID_MFA_REQUIRED_BANNER = "MFARequiredBanner";

const MFARequiredBanner = (): React.JSX.Element | null => {
  const forceMfaEnrolment = useSelector(selectForceMfaEnrolment);

  if (!forceMfaEnrolment) return null;

  return (
    <NotificationBanner icon={faLock} testId={TEST_ID_MFA_REQUIRED_BANNER}>
      You must enable <strong>multi-factor authentication</strong> to access results.
    </NotificationBanner>
  );
};

export default MFARequiredBanner;
