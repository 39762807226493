import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface NotificationBannerProps {
  icon?: IconDefinition;
  children: React.ReactNode;
  // Extend from https://versions.bulma.io/0.9.4/documentation/elements/notification/#colors
  color?: "primary" | "warning" | "danger";
  testId?: string;
}

export const TEST_ID_NOTIFICATION_BANNER = "NotificationBanner";

const NotificationBanner = ({
  icon,
  children,
  color = "primary",
  testId = TEST_ID_NOTIFICATION_BANNER,
}: NotificationBannerProps): React.JSX.Element => {
  return (
    <div className={`notification mb-6 is-${color}`} data-testid={testId}>
      <div className="level level-left is-mobile">
        {icon && <FontAwesomeIcon icon={icon} className="mr-4" />}
        <span>{children}</span>
      </div>
    </div>
  );
};

export default NotificationBanner;
