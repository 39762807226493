import React, { useEffect } from "react";

import PageHeader from "../components/common/PageHeader";
import { CYTED_HEALTH } from "../helpers/strings";

export const TEST_ID_ERROR_PAGE_CONTENT = "ErrorPageContent";

const Error = (): React.JSX.Element => {
  // Page title
  const pageTitle = "Error";
  useEffect(() => {
    document.title = `${pageTitle} | ${CYTED_HEALTH}`;
  }, []);

  return (
    <div className="container is-max-desktop">
      <main className="section pt-0">
        <PageHeader title={pageTitle} subtitle="We’re sorry" />
        <div className="columns content">
          <div className="column is-two-thirds" data-testid={TEST_ID_ERROR_PAGE_CONTENT}>
            <p>
              The site has encountered an unexpected error and this page is currently
              unavailable.
            </p>
            <p>
              Our engineers have been automatically notified of this error and will work
              to resolve it as soon as possible.
            </p>
            <p>
              Please log out and try again, or contact{" "}
              <a href="mailto:service@cytedhealth.com">service@cytedhealth.com</a> for
              help.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Error;
