import React, { ChangeEvent } from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../store";
import { fetchAllReports, updateFilterOptions } from "../store/reportsSlice";
import { UserGroup } from "../types/userGroup";

export const TEST_ID_REPORT_GROUP_FILTER = "ReportGroupFilter";

interface ReportGroupFilterProps {
  userGroups: UserGroup[];
  selectedGroup: string;
}

export enum GroupOption {
  All = "all",
}

const ReportGroupFilter = ({
  userGroups,
  selectedGroup,
}: ReportGroupFilterProps): React.JSX.Element => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    const userGroupId = e.target.value;
    dispatch(updateFilterOptions({ userGroupId }));
    dispatch(fetchAllReports({ showSpinner: true }));
  };

  return (
    <div className="field">
      <label className="label" htmlFor="userGroup">
        Show
      </label>
      <div className="control">
        <div className="select is-small is-fullwidth is-rounded cyt-report-group-filter">
          <select
            id="userGroup"
            name="userGroup"
            value={selectedGroup}
            onChange={handleChange}
            data-testid={TEST_ID_REPORT_GROUP_FILTER}
          >
            {userGroups.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default ReportGroupFilter;
