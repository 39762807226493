import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";

import LoadingBackdrop from "../components/common/LoadingBackdrop";
import Navbar from "../components/navigation/Navbar";
import { RootState } from "../store";
import Error from "./Error";

const Root = (): React.JSX.Element => {
  // Redux
  const { status } = useSelector((state: RootState) => state.auth);

  return (
    <>
      <Navbar />
      {status === "failed" && <Error />}
      {status === "pending" && <LoadingBackdrop />}
      {status === "succeeded" && <Outlet />}
    </>
  );
};

export default Root;
