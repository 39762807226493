import { signOut } from "aws-amplify/auth";
import { useSnackbar } from "notistack";
import { MouseEvent } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { AppDispatch } from "../store";
import { resetAuthState } from "../store/authSlice";
import { resetReportsState } from "../store/reportsSlice";

export const useHandleSignOut = () => {
  const { closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleSignOut = async (e?: MouseEvent) => {
    e?.preventDefault();
    try {
      closeSnackbar();
      await signOut();
      dispatch(resetAuthState());
      dispatch(resetReportsState());
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return handleSignOut;
};
