import { useAuthenticator } from "@aws-amplify/ui-react";
import { faArrowRightFromBracket, faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router";

import logo from "../../assets/Cyted-Logo-RGB_Blue.png";
import { useHandleSignOut } from "../../helpers/auth";
import { UrlRoutes } from "../../main";
import { selectCurrentUser } from "../../store/authSlice";

const Navbar = (): React.JSX.Element => {
  const { user } = useAuthenticator((context) => [context.user]);
  const handleSignOut = useHandleSignOut();

  // Redux
  const { firstName = "", lastName = "" } = useSelector(selectCurrentUser);

  // Name (fall back to email address)
  const userFullName = `${firstName} ${lastName}`.trim();
  const userDisplayName = userFullName || user?.username;

  // Initials
  const [firstInitial = ""] = firstName;
  const [lastInitial = ""] = lastName;
  const userInitials = `${firstInitial}${lastInitial}`;

  const AuthLinks = (): React.JSX.Element | null => {
    if (!user) return null;
    return (
      <div className="navbar-end">
        <Link to={UrlRoutes.SETTINGS} className="navbar-item">
          <FontAwesomeIcon icon={faCog} size="sm" className="mr-2" />
          <span className="is-hidden-mobile">{userDisplayName}</span>
          <span className="is-hidden-tablet is-uppercase">{userInitials}</span>
        </Link>
        <a href="" className="navbar-item" onClick={handleSignOut}>
          <FontAwesomeIcon icon={faArrowRightFromBracket} size="sm" className="mr-2" />
          <span className="is-hidden-mobile">Sign out</span>
          <span className="is-hidden-tablet">Exit</span>
        </a>
      </div>
    );
  };

  return (
    <nav role="navigation" className="navbar is-transparent">
      <div className="section container is-max-desktop py-0">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src={logo} alt="Cyted Health logo" />
          </Link>
        </div>
        <AuthLinks />
      </div>
    </nav>
  );
};

export default Navbar;
