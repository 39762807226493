import { faRepeat } from "@fortawesome/free-solid-svg-icons";
import { filter } from "lodash";
import React from "react";

import { issueTypeLabel } from "../helpers/strings";
import { IssueType, Report } from "../types/report";
import NotificationBanner from "./common/NotificationBanner";

export const TEST_ID_REISSUED_REPORTS_BANNER = "ReissuedReportsBanner";

interface ReissuedReportsBannerProps {
  reports?: Report[];
}

const getMessage = (count: number, issueType: IssueType): string => {
  return ` ${count} ${issueTypeLabel[issueType]} report${count > 1 ? "s" : ""} `.toLowerCase();
};

const ReissuedReportsBanner = ({
  reports,
}: ReissuedReportsBannerProps): React.JSX.Element | null => {
  const { REISSUED, AMENDED } = IssueType;

  const reissuedReports = filter(reports, ["issueType", REISSUED]);
  const amendedReports = filter(reports, ["issueType", AMENDED]);
  const reissuedReportsMessage = getMessage(reissuedReports.length, REISSUED);
  const amendedReportsMessage = getMessage(amendedReports.length, AMENDED);
  const hasReissuedReports = reissuedReports.length > 0;
  const hasAmendedReports = amendedReports.length > 0;

  if (!hasReissuedReports && !hasAmendedReports) return null;

  return (
    <NotificationBanner
      icon={faRepeat}
      color="warning"
      testId={TEST_ID_REISSUED_REPORTS_BANNER}
    >
      You have{hasReissuedReports && <strong>{reissuedReportsMessage}</strong>}
      {hasReissuedReports && hasAmendedReports && "and"}
      {hasAmendedReports && <strong>{amendedReportsMessage}</strong>}in your inbox.
    </NotificationBanner>
  );
};

export default ReissuedReportsBanner;
