import { format } from "date-fns";
import { chain, sortBy } from "lodash";
import React, { ChangeEvent } from "react";

import { managementPriorityLabel } from "../helpers/strings";
import { ManagementPriority, Report } from "../types/report";
import ReportCard from "./ReportCard";
import { SortOption } from "./ReportSortToggle";

export const TEST_ID_REPORT_GROUP_SECTION = "ReportGroupSection";
export const TEST_ID_REPORT_GROUP_HEADING = "ReportGroupHeading";

interface ReportListProps {
  reports: Report[];
  currentSearch: string;
  currentSort: SortOption;
  selectedReports: string[];
  updateSelectedReports: (e: ChangeEvent<HTMLInputElement>) => void;
}

const sortReportPropertyMap: Record<SortOption, keyof Report> = {
  [SortOption.Priority]: "managementPriority",
  [SortOption.ClinicDate]: "procedureDate",
};

const managementPrioritySortOrder: Record<ManagementPriority, number> = {
  [ManagementPriority.URGENT]: 1,
  [ManagementPriority.INADEQUATE]: 2,
  [ManagementPriority.ROUTINE]: 3,
  [ManagementPriority.NORMAL]: 4,
  [ManagementPriority.NULL]: 5,
};

const ReportList = ({
  reports,
  currentSearch,
  currentSort,
  selectedReports,
  updateSelectedReports,
}: ReportListProps): React.JSX.Element => {
  const getReportGroupHeading = (group: string): string => {
    switch (currentSort) {
      case SortOption.Priority:
        return managementPriorityLabel[group as ManagementPriority].group;
      case SortOption.ClinicDate:
        return format(group, "iiii, d MMMM yyyy");
      default:
        return group;
    }
  };

  const getReportGroupSort = (group: string) => {
    switch (currentSort) {
      case SortOption.Priority:
        return managementPrioritySortOrder[group as ManagementPriority];
      case SortOption.ClinicDate:
        // Ascending procedure date (ISO format)
        return group;
      default:
        return group;
    }
  };

  // Group reports according to UI toggle, then sort those groups, e.g. urgent
  // cases first, oldest to most recent clinic date etc.
  const groupedReports = chain(reports)
    .groupBy(sortReportPropertyMap[currentSort])
    .map((reports, group) => ({ group, reports }))
    .sortBy((item) => getReportGroupSort(item.group))
    .value();

  return (
    <>
      {groupedReports.map(({ group, reports }) => {
        // Sort every group of reports firstly by descending priority, then by oldest
        // to most recent clinic date, then alphabetically by patient surname.
        const sortedReports: Report[] = sortBy(reports, [
          (c) => managementPrioritySortOrder[c.managementPriority as ManagementPriority],
          "procedureDate",
          "patientSurname",
        ]);
        return (
          <section
            className="block mb-6"
            key={group}
            data-testid={TEST_ID_REPORT_GROUP_SECTION + group}
          >
            <h4 className="title is-5" data-testid={TEST_ID_REPORT_GROUP_HEADING + group}>
              {getReportGroupHeading(group)}
            </h4>
            <ul>
              {sortedReports.map((report) => (
                <ReportCard
                  key={report.reportId}
                  report={report}
                  currentSearch={currentSearch}
                  isSelected={selectedReports.includes(report.reportId)}
                  updateSelectedReports={updateSelectedReports}
                />
              ))}
            </ul>
          </section>
        );
      })}
    </>
  );
};

export default ReportList;
