import { useAuthenticator } from "@aws-amplify/ui-react";
import { isBoolean } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";

import Navbar from "../components/navigation/Navbar";
import { UrlRoutes } from "../main";
import { AppDispatch } from "../store";
import {
  fetchUserData,
  fetchUserGroups,
  selectCurrentUser,
  selectForceMfaEnrolment,
} from "../store/authSlice";
import Login from "./Login";

interface AccessControlGuardProps {
  children: React.ReactNode;
}

const AccessControlGuard: React.FC<AccessControlGuardProps> = ({ children }) => {
  const { pathname } = useLocation();
  const { user } = useAuthenticator((context) => [context.user]);

  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const { isMfaEnabled } = useSelector(selectCurrentUser);
  const forceMfaEnrolment = useSelector(selectForceMfaEnrolment);

  useEffect(() => {
    if (user) {
      dispatch(fetchUserData());
      dispatch(fetchUserGroups());
    }
  }, [user, dispatch]);

  // Never proceed without both a user and their MFA setting
  if (!user || !isBoolean(isMfaEnabled)) {
    return (
      <>
        <Navbar />
        <Login />
      </>
    );
  }

  // Redirect to MFA enrolment if required by user group policy
  if (forceMfaEnrolment && pathname !== `/${UrlRoutes.SETTINGS}`) {
    return <Navigate to={UrlRoutes.SETTINGS} replace />;
  }

  return children;
};

export default AccessControlGuard;
