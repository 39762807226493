import { faCircleInfo, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { managementPriorityLabel } from "../../helpers/strings";
import { ManagementPriority, Report } from "../../types/report";
import DotSeparator from "./DotSeparator";

export const TEST_ID_REPORT_CARD_HEADER = "ReportCardHeader";
export const TEST_ID_REPORT_CARD_HEADER_ICON = "ReportCardHeaderIcon";
export const TEST_ID_REPORT_CARD_HEADER_CASE_TYPE = "ReportCardHeaderCaseType";
export const TEST_ID_REPORT_CARD_HEADER_MANAGEMENT_PRIORITY =
  "ReportCardHeaderManagementPriority";

export interface ReportCardHeaderProps {
  report: Report;
}

const ReportCardHeader = ({
  report: { reportId, caseType, managementPriority },
}: ReportCardHeaderProps): React.JSX.Element => {
  const isUrgentOrInadequate: boolean =
    managementPriority === ManagementPriority.URGENT ||
    managementPriority === ManagementPriority.INADEQUATE;
  const priorityLabel = managementPriorityLabel[managementPriority].card;

  return (
    <div
      className="cyt-report-card__header"
      data-testid={TEST_ID_REPORT_CARD_HEADER + reportId}
    >
      <FontAwesomeIcon
        icon={isUrgentOrInadequate ? faExclamationTriangle : faCircleInfo}
        data-testid={TEST_ID_REPORT_CARD_HEADER_ICON + reportId}
      />
      <span data-testid={TEST_ID_REPORT_CARD_HEADER_CASE_TYPE + reportId}>
        {caseType}
      </span>
      {!!priorityLabel && (
        <>
          <DotSeparator />
          <span data-testid={TEST_ID_REPORT_CARD_HEADER_MANAGEMENT_PRIORITY + reportId}>
            {priorityLabel}
          </span>
        </>
      )}
    </div>
  );
};

export default ReportCardHeader;
