import { useAuthenticator } from "@aws-amplify/ui-react";
import { isBoolean } from "lodash";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import Box from "../components/common/Box";
import PageHeader from "../components/common/PageHeader";
import ChangePassword from "../components/settings/ChangePassword";
import MFAComplete from "../components/settings/MFAComplete";
import MFARequiredBanner from "../components/settings/MFARequiredBanner";
import MFASetup from "../components/settings/MFASetup";
import { CYTED_HEALTH } from "../helpers/strings";
import { selectCurrentUser } from "../store/authSlice";

const Settings = (): React.JSX.Element => {
  const { user } = useAuthenticator((context) => [context.user]);

  // Redux
  const { isMfaEnabled } = useSelector(selectCurrentUser);

  // Page title
  const pageTitle = "Settings";
  useEffect(() => {
    document.title = `${pageTitle} | ${CYTED_HEALTH}`;
  }, []);

  return (
    <div className="container is-max-desktop">
      <main className="section pt-0">
        <PageHeader title={pageTitle} subtitle={user.username} />
        <MFARequiredBanner />
        {isBoolean(isMfaEnabled) && (
          <Box title="Security">{isMfaEnabled ? <MFAComplete /> : <MFASetup />}</Box>
        )}
        <Box title="Change password">
          <ChangePassword />
        </Box>
      </main>
    </div>
  );
};

export default Settings;
